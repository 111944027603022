import preset from '@bit/medicalwebexperts.mwe-ui.themes.preset';

export default {
  ...preset,
  colors: {
    ...preset.colors,
    primary: {
      main: '#43a4e8',
    },
  },
};
