import React from 'react';
import { node } from 'prop-types';
import Normalize from '@bit/medicalwebexperts.mwe-ui.normalize';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ThemeProvider from '@bit/medicalwebexperts.mwe-ui.theme-provider';

import theme from './themes/default';

const propTypes = {
  children: node.isRequired,
};

const Root = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_CAPTCHA_SITE_ID}
      container={{
        element: 're-captcha-container',
        parameters: { badge: 'inline' },
      }}
    >
      <Normalize />
      {children}
    </GoogleReCaptchaProvider>
  </ThemeProvider>
);

Root.propTypes = propTypes;

export default Root;
